import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Supplication avant l'Apocalypse" />
    <h3 className='underline-title'>Supplication avant l'Apocalypse</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>        
        Dieu<br />
        Fais que nous soyons dans mon patelin<br />
        Á l'abri des diadoques alexandresques<br />
        Quand leurs armes se heurteront en des chocs sinistres<br />
      </p>
      <p> 
        Jéhovah de ses Témoins<br />
        Comme Ptolémée mets ma famille<br />
        Á l'abri des agitations soldatesques<br />
      </p>
      <p> 
        Dans la vallée mystérieuse de Wambè-la-déroutante<br />
        Où les lieutenants d'Alexandre n'ont aucun butin à emporter<br />
      </p>
      <p> 
        Jahvé des Anciens<br />
        Ces Généraux insatiables s'égorgeront pour succession<br />
        Ivres de richesses imméritées et de sang innocent<br />
        Fais que nous ne soyons victimes de ces Keraunos-le-foudre<br />
        Que nous ne tombions sous leurs armes rougies de sang<br />
      </p>
      <p> 
        Seigneur<br />
        Exauce notre prière<br />
        Aie pitié de nous!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
